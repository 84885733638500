import './mailchamp.css'
import {useState} from "react";

export default function EmailSubscribe() {
	const [formData, setFormData] = useState({
		email: ''
	});
	const [subscribing, setSubscribing] = useState(false);
	const handleChange = (e) => {
		setFormData({
			...formData, [e.target.name]: e.target.value
		});
	};
	
	async function handleSubmit(event) {
		event.preventDefault();
		setSubscribing(true);
		document.getElementById("subscribeMailChampBtn").innerText = 'Subscribing'
		try {
			const response = await fetch('https://mailchamp.ai:8080/clientsubscription', {
				method: 'POST', headers: {
					'Content-Type': 'application/json'
				}, body: JSON.stringify(formData)
			}).then(() => {
				document.getElementById("subscribeMailChampBtn").innerText = 'Confirmed !'
				setFormData({'email': ''})
			})
			if (!response.ok) {
				// throw new Error(`HTTP error! status: ${response.status}`);
			}
		} catch (error) {
			console.error('There was an error!', error);
		}
	}
	
	return (<div style={{width: "100%", display: "flex", justifyContent: "center"}}>
		<div style={{
			textAlign: "left",
			paddingLeft: "30px",
			paddingRight: "30px",
			marginBottom: "10px",
			maxWidth: "600px",
			minHeight: "150px",
			backgroundColor: "blue",
			borderRadius: "10px",
			paddingTop: "10px",
			paddingBottom: "20px"
		}}>
			<p style={{color: 'white'}}>StarzBuzz Daily</p>
			<p style={{color: 'white'}}>Keep up with the latest daily buzz with the BuzzFeed Daily newsletter!</p>
		<form onSubmit={handleSubmit}>
			<label className='label'>Email</label>
			<input id='email' name='email' type='email' className='input'
			       onChange={handleChange}
			       value={formData.email}
			       required={true}
			/>
			<button id='subscribeMailChampBtn' name='subscribeMailChampBtn' type="submit" className='subscribeButton' disabled={subscribing}>
				Subscribe For NewsFeed
			</button>
		</form>
		</div>
	</div>)
}