import YoutubeEmbed from "./YoutubeEmbed";

export default function MovieCard({isMobile}) {
	
	return (<div style={{display: `${isMobile ? 'inline-grid' : "inline-flex"}`, marginTop: "10px"}}>
		<div>
			<YoutubeEmbed embedId="mg0KO0N30dQ"/>
		</div>
		<div style={{
			marginLeft: "20px", textAlign: "left", border: "1px solid grey", borderRadius: "10px",
			paddingLeft: "20px", paddingRight: "20px", paddingTop: "10px", paddingBottom: "5px"
		}}>
			<p className='paragraph'>Trailer: American Sniper 3</p>
			<p className='paragraph'>Genre: Action</p>
			<p className='paragraph'>Hollywood</p>
			<p className='paragraph'>StarCast: </p>
			<p className='paragraph'>Platform: Theaters</p>
			<p className='paragraph'>Release Date: 01/31/2024</p>
		</div>
	</div>)
}