import React, {useEffect, useState} from 'react';
import './Home.css'
// import qrImage from '../assets/qr_code.png'
import EmailSubscribe from "./MailChamp/EmailSubscribe";
import MovieCard from "./MovieCard";

export default function Home() {
	const [isMobile, setIsMobile] = useState(false);
	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 1000);
		};
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);
	
	const [selectedGenres, setSelectedGenres] = useState([]);
	
	const toggleGenre = (genre) => {
		if (selectedGenres.includes(genre)) {
			// If the genre is already selected, remove it from the list
			setSelectedGenres((prevSelected) =>
				prevSelected.filter((selected) => selected !== genre)
			);
		} else {
			// If the genre is not selected, add it to the list
			setSelectedGenres((prevSelected) => [...prevSelected, genre]);
		}
	};
	
	const isGenreSelected = (genre) => selectedGenres.includes(genre);
	
	
	return (<div style={{flex: 1}}>
		<div class="title-bar">
			<p>StarzBuzz: Get the Movies Buzz</p>
		</div>
		<div className="container">
			<div style={{display: `${isMobile ? 'inline-grid' : "inline-flex"}`, marginBottom: "20px"}}>
				<h6 style={{textAlign: "left", marginTop: "4px", marginLeft: "-100px"}}>Select Genre</h6>
				<button className={`genreButton ${isGenreSelected('Action') ? 'selected' : ''}`} onClick={() => toggleGenre('Action')}>
					Action
				</button>
				<button
					className={`genreButton ${isGenreSelected('Thriller') ? 'selected' : ''}`}
					onClick={() => toggleGenre('Thriller')}
				>
					Thriller
				</button>
				<button
					className={`genreButton ${isGenreSelected('Comedy') ? 'selected' : ''}`}
					onClick={() => toggleGenre('Comedy')}
				>
					Comedy
				</button>
				<button
					className={`genreButton ${isGenreSelected('Drama') ? 'selected' : ''}`}
					onClick={() => toggleGenre('Drama')}
				>
					Drama
				</button>
				<button
					className={`genreButton ${isGenreSelected('Romance') ? 'selected' : ''}`}
					onClick={() => toggleGenre('Romance')}
				>
					Romance
				</button>
			</div>
			<EmailSubscribe/>
			<hr/>
			<div style={{display: `${isMobile ? 'inline-grid' : "flow"}`, marginBottom: "20px", width: "100%"}}>
				<p style={{textAlign: "left", marginTop: "-20px", padding: "20px", marginBottom: "-30px"}}>1 Day Ago Buzz</p>
				<MovieCard isMobile={isMobile}/>
				<MovieCard isMobile={isMobile}/>
				<MovieCard isMobile={isMobile}/>
				<MovieCard isMobile={isMobile}/>
				<MovieCard isMobile={isMobile}/>
			</div>
			
			<div style={{display: `${isMobile ? 'inline-grid' : "flow"}`, marginBottom: "20px", width: "100%"}}>
				<p style={{textAlign: "left", marginTop: "-20px", padding: "20px", marginBottom: "-30px"}}>2 Days Ago Buzz</p>
				<MovieCard isMobile={isMobile}/>
				<MovieCard isMobile={isMobile}/>
				<MovieCard isMobile={isMobile}/>
				<MovieCard isMobile={isMobile}/>
				<MovieCard isMobile={isMobile}/>
			</div>
			
			<div style={{display: `${isMobile ? 'inline-grid' : "flow"}`, marginBottom: "20px", width: "100%"}}>
				<p style={{textAlign: "left", marginTop: "-20px", padding: "20px", marginBottom: "-30px"}}>1 Week Ago Buzz</p>
				<MovieCard isMobile={isMobile}/>
				<MovieCard isMobile={isMobile}/>
				<MovieCard isMobile={isMobile}/>
				<MovieCard isMobile={isMobile}/>
				<MovieCard isMobile={isMobile}/>
			</div>
			
			<div style={{display: `${isMobile ? 'inline-grid' : "flow"}`, marginBottom: "20px", width: "100%"}}>
				<p style={{textAlign: "left", marginTop: "-20px", padding: "20px", marginBottom: "-30px"}}>2 Week Ago Buzz</p>
				<MovieCard isMobile={isMobile}/>
				<MovieCard isMobile={isMobile}/>
				<MovieCard isMobile={isMobile}/>
				<MovieCard isMobile={isMobile}/>
				<MovieCard isMobile={isMobile}/>
			</div>
			
			<div style={{display: `${isMobile ? 'inline-grid' : "flow"}`, marginBottom: "20px", width: "100%"}}>
				<p style={{textAlign: "left", marginTop: "-20px", padding: "20px", marginBottom: "-30px"}}>3 Week Ago Buzz</p>
				<MovieCard isMobile={isMobile}/>
				<MovieCard isMobile={isMobile}/>
				<MovieCard isMobile={isMobile}/>
				<MovieCard isMobile={isMobile}/>
				<MovieCard isMobile={isMobile}/>
			</div>
			
			<div style={{display: `${isMobile ? 'inline-grid' : "flow"}`, marginBottom: "20px", width: "100%"}}>
				<p style={{textAlign: "left", marginTop: "-20px", padding: "20px", marginBottom: "-30px"}}>1 year Ago Buzz</p>
				<MovieCard isMobile={isMobile}/>
				<MovieCard isMobile={isMobile}/>
				<MovieCard isMobile={isMobile}/>
				<MovieCard isMobile={isMobile}/>
				<MovieCard isMobile={isMobile}/>
			</div>
		</div>
	</div>)
}